import http from '@/utils/http.js'

// 获取菜单
export function channelList(data) {
    return http({
        url: "/jasoboss/niche/channel/list",
        method: "post",
        data
    })
}

//商机-渠道管理(列表)
export function channeManagelList(data) {
    return http({
        url: "/jasoboss/niche/channel/manage/list",
        method: "post",
        data
    })
}
//商机-渠道管理添加
export function channeManagelAdd(data) {
    return http({
        url: "/jasoboss/niche/channel/manage/add",
        method: "post",
        data
    })
}
//商机-渠道管理添加
export function channeManagelUpdate(data) {
    return http({
        url: "/jasoboss/niche/channel/manage/update",
        method: "post",
        data
    })
}