<template>
    <div class="trial_box">
        <BaseInfo ref="baseInfo"></BaseInfo>
        <el-form
            ref="form"
            size="small"
            label-position="left"
            label-width="80px"
        >
            <el-form-item label="试用域名" style="margin-left:-10px">
                <div class="brand">{{ tableRow.trialDomain || '- -' }}</div>
            </el-form-item>
        </el-form>
        <el-form
            ref="form"
            size="small"
            label-position="left"
            label-width="80px"
        >
            <el-form-item label="变更域名" required style="margin-left:-10px">
                <el-select
                    v-model="form.companyDomain"
                    clearable
                    placeholder="请选择"
                    style="width:240px;"
                >
                    <el-option
                        v-for="item in domainList"
                        :key="item.domain"
                        :disabled="isNowData(item)"
                        :label="item.domain"
                        :value="item.domain"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" style="margin-left:-10px">
                <el-input
                    type="textarea"
                    v-model="form.applyRemark"
                    placeholder="请输入备注"
                    style="width:240px"
                    rows="6"
                ></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { reportDomainList } from '@/api/report/report.js';
import BaseInfo from '../components/baseInfo.vue';
export default {
    data() {
        return {
            id: '',
            form: {
                companyDomain: '',
                applyRemark: '',
            },
            domainList: [],
            tableRow: {},
        };
    },
    components: {
        BaseInfo,
    },
    methods: {
        getData(row) {
            this.form.companyDomain = '';
            this.form.applyRemark = '';
            sessionStorage.setItem('customerlist', 1);
            this.reportDomainList(row.id);
            this.tableRow = row;
            setTimeout(() => {
                this.$refs.baseInfo.getData(row);
            }, 0);
        },
        reportDomainList(nicheId) {
            let data = {
                param: {
                    nicheId,
                    // auditStatus: 1,
                },
            };
            reportDomainList(data).then((res) => {
                if (res.code == 200) {
                    this.domainList = res.data.list.filter((item) => {
                        return (
                            item.auditStatus == 1 &&
                            this.tableRow.trialDomain != item.domain
                        );
                    });
                }
            });
        },
        isNowData(data) {
            if (data.auditStatus == 2) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.trial_box {
    height: 100%;
    width: 80%;
    margin: 0px auto 40px;
    .el-form {
        width: 80%;
        margin: 0 auto;
    }
    /deep/ .el-textarea__inner {
        resize: none;
    }
}
.brand {
    display: inline-block;
    width: 240px;
    box-sizing: border-box;
    padding-left: 10px;
    background: #fafafa;
    color: #333333;
    font-size: 14px;
    border-radius: 2px;
}
</style>
