<template>
    <div class="trial">
        <BaseInfo ref="baseInfo"></BaseInfo>
        <el-form
            ref="form"
            label-position="left"
            size="small"
            label-width="80px"
        >
            <el-form-item label="域名" style="margin-left: -10px">
                <span class="brand">{{ tableRow.trialDomain || '- -' }}</span>
            </el-form-item>
            <el-form-item label="用户数" style="margin-left: -10px">
                <span class="brand">{{ tableRow.trialNumber || '- -' }}</span>
            </el-form-item>
            <el-form-item label="版本" style="margin-left: -10px">
                <span class="brand">{{
                    tableRow.emailVersionType == 7
                        ? 'Hmail旗舰版'
                        : tableRow.emailVersionType == 8
                        ? 'Hmail 5G'
                        : tableRow.emailVersionType == 9
                        ? '尊享版'
                        : '- -'
                }}</span>
            </el-form-item>
        </el-form>
        <el-form
            ref="form"
            label-position="left"
            size="small"
            label-width="80px"
        >
            <el-form-item label="延期天数" required style="margin-left: -10px">
                <el-input
                    v-model="delayForm.delayDays"
                    placeholder="请输入延期天数"
                    style="width: 240px"
                    disabled
                    @input="
                        () => {
                            delayForm.delayDays = delayForm.delayDays.replace(
                                /[^0-9]/g,
                                ''
                            );
                        }
                    "
                ></el-input>
            </el-form-item>
            <el-form-item label="备注" style="margin-left: -10px">
                <el-input
                    type="textarea"
                    v-model="delayForm.applyRemark"
                    placeholder="请输入备注"
                    style="width: 240px"
                    rows="6"
                ></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import BaseInfo from '../components/baseInfo.vue';
import { reportUpdate } from '@/api/report/report';
export default {
    data() {
        return {
            delayForm: {
                delayDays: 23,
            },
            tableRow: {},
        };
    },
    components: {
        BaseInfo,
    },
    methods: {
        getData(row) {
            this.delayForm = {
                delayDays: 23,
            };
            sessionStorage.setItem('customerlist', 1);
            this.tableRow = row;
            setTimeout(() => {
                this.$refs.baseInfo.getData(row);
            }, 0);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.trial {
    width: 80%;
    margin: 0px auto 40px;
    .el-form {
        width: 80%;
        margin: 0 auto;
    }
    .el-input {
        width: 270px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
    .brand {
        display: inline-block;
        width: 240px;
        box-sizing: border-box;
        padding-left: 10px;
        background: #fafafa;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
}
</style>
