<template>
    <div class="base_info_box">
        <el-form
            ref="form"
            label-position="left"
            size="small"
            label-width="80px"
        >
            <el-form-item label="报备品牌" style="margin-left: -10px">
                <span class="brand">网易企业邮箱</span>
            </el-form-item>
            <el-form-item label="报备名称" style="margin-left: -10px">
                <span class="brand">{{ tableRow.reportName || '- -' }}</span>
            </el-form-item>
            <el-form-item
                :label="tableRow.nicheCategory == 1 ? '客户' : '报备渠道'"
                style="margin-left: -10px"
            >
                <span class="brand">{{
                    tableRow.nicheCategory == 1
                        ? tableRow.companyName || '- -'
                        : tableRow.channelManageName || '- -'
                }}</span>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            tableRow: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row) {
            this.tableRow = row;
        },
    },
};
</script>

<style lang="scss" scoped>
.base_info_box {
    width: 100%;
    overflow: hidden;
    .brand {
        display: inline-block;
        width: 240px;
        box-sizing: border-box;
        padding-left: 10px;
        background: #fafafa;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-form {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
    }
}
</style>
