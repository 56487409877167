<template>
    <div class="delay_box">
        <BaseInfo ref="baseInfo"></BaseInfo>
        <el-form
            ref="form"
            label-position="left"
            size="small"
            label-width="80px"
        >
            <el-form-item label="延期天数" required style="margin-left: -10px">
                <el-input
                    v-model="delayForm.delayDays"
                    placeholder="请输入延期天数"
                    style="width: 240px"
                    @input="
                        () => {
                            delayForm.delayDays = delayForm.delayDays.replace(
                                /[^0-9]/g,
                                ''
                            );
                        }
                    "
                ></el-input>
            </el-form-item>
            <el-form-item label="备注" style="margin-left: -10px">
                <el-input
                    type="textarea"
                    v-model="delayForm.applyRemark"
                    placeholder="请输入备注"
                    style="width: 240px"
                    rows="6"
                ></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { reportUpdate, reportList } from '@/api/report/report.js';
import BaseInfo from '../components/baseInfo.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            delayForm: {
                delayDays: 30,
            },
            tableRow: {},
        };
    },
    components: {
        BaseInfo,
    },
    created() {},
    methods: {
        getData(row) {
            this.delayForm = {
                delayDays: 30,
            };
            sessionStorage.setItem('customerlist', 1);
            this.tableRow = row;
            setTimeout(() => {
                this.$refs.baseInfo.getData(row);
            }, 0);
        },
    },
};
</script>

<style lang="scss" scoped>
.delay_box {
    width: 80%;
    margin: 0px auto 40px;
    .el-form {
        width: 80%;
        margin: 0 auto;
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
</style>
