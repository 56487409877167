<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                width="100"
                label="商机ID"
                prop="nicheNo"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    {{ scope.row.nicheNo || '- -' }}
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报备名称" width="239" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        v-if="btnP.details"
                        type="text"
                        style="color: #2370eb"
                        @click="onReportDetail(scope.row)"
                        >{{ scope.row.reportName || '- -' }}</el-button
                    >
                    <span v-else>{{ scope.row.reportName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="报备渠道"
                v-if="btnP.channel"
                width="100"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.channelManageName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column v-if="btnP.channel" min-width="20">
            </el-table-column>
            <el-table-column label="域名" width="145">
                <template slot-scope="scope">
                    <div>
                        <el-tooltip effect="dark" placement="bottom">
                            <div>
                                {{ returnDomain(scope.row).domain }}
                            </div>
                            <div slot="content" class="item">
                                <p
                                    v-for="item in scope.row.nicheDomainBOList"
                                    :key="item.id"
                                >
                                    <span
                                        style="
                                            display: inline-block;
                                            width: 200px;
                                            text-align: left;
                                        "
                                    >
                                        {{
                                            item.domain +
                                                ',用户数：' +
                                                item.number || 0
                                        }}
                                    </span>
                                    <span
                                        class="success"
                                        v-if="item.auditStatus == 1"
                                    >
                                        <i class="el-icon-success"></i>
                                        成功
                                    </span>
                                    <span
                                        class="faid"
                                        v-if="item.auditStatus == 2"
                                    >
                                        <i class="el-icon-warning"></i>
                                        失败
                                    </span>
                                    <span
                                        class="wait"
                                        v-if="item.auditStatus == 0"
                                        ><i class="iconfont icon-dengdai"></i>
                                        待处理</span
                                    >
                                </p>
                            </div>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报备时间" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span v-if="scope.row.reportStartTime">{{
                        scope.row.reportStartTime.slice(0, 10)
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="回收时间" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span>{{
                        returnDomain(scope.row).expireTime
                            ? returnDomain(scope.row).expireTime.slice(0, 10)
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="试用到期时间"
                show-overflow-tooltip
                width="93"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.trialExpireTime">{{
                        scope.row.trialExpireTime.slice(0, 10)
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="状态" width="170" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="btnP.history">
                        <!-- <i
                            class="iconfont icon-ziyuanxhdpi"
                            @click="onHistory(scope.row)"
                        ></i> -->
                        {{
                            $MailStatus.reportStatus(scope.row.reportStatus) +
                            '-' +
                            $MailStatus.trialStatus(scope.row.trialStatus)
                        }}</span
                    >
                    <span v-else style="padding-left: 20px">
                        {{
                            $MailStatus.reportStatus(scope.row.reportStatus) +
                            '-' +
                            $MailStatus.trialStatus(scope.row.trialStatus)
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="报备库"
                prop="followAdminName"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.libraryName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" fixed="right" width="180">
                <template slot-scope="scope" v-if="isShow(scope.row)">
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="btnP.again && btnStatus('重新报备', scope.row)"
                        @click="
                            ($event) => {
                                onBtnFun($event, scope.row);
                            }
                        "
                        >重新报备</el-button
                    >
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="btnP.add && btnStatus('申请报备', scope.row)"
                        @click="
                            ($event) => {
                                onBtnFun($event, scope.row);
                            }
                        "
                        >申请报备</el-button
                    >
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="btnP.update && btnStatus('编辑', scope.row)"
                        @click="
                            ($event) => {
                                onBtnFun($event, scope.row);
                            }
                        "
                        >编辑</el-button
                    >

                    <el-button
                        style="color: #2370eb"
                        v-if="btnP.revoke && btnStatus('撤销报备', scope.row)"
                        type="text"
                        @click="
                            ($event) => {
                                onBtnFun($event, scope.row);
                            }
                        "
                        >撤销报备</el-button
                    >
                    <el-button
                        style="color: #2370eb"
                        v-if="btnP.addTrial && btnStatus('试用申请', scope.row)"
                        type="text"
                        @click="
                            ($event) => {
                                onBtnFun($event, scope.row);
                            }
                        "
                        >试用申请</el-button
                    >
                    <el-button
                        style="color: #2370eb"
                        v-if="
                            btnP.addDomain && btnStatus('添加域名', scope.row)
                        "
                        type="text"
                        @click="
                            ($event) => {
                                onBtnFun($event, scope.row);
                            }
                        "
                        >添加域名</el-button
                    >
                    <el-button
                        style="color: #2370eb"
                        v-if="
                            btnP.addDomain &&
                            btnStatus('试用延期', scope.row) &&
                            scope.row.trialDays != 30
                        "
                        type="text"
                        @click="
                            ($event) => {
                                onBtnFun($event, scope.row);
                            }
                        "
                        >试用延期</el-button
                    >

                    <el-dropdown
                        v-if="
                            (btnP.changeTrial &&
                                btnStatus('变更试用', scope.row)) ||
                            (btnP.giveup && btnStatus('放弃报备', scope.row)) ||
                            (btnP.delay && btnStatus('报备延期', scope.row))
                        "
                        trigger="click"
                    >
                        <span class="el-dropdown-link">
                            <i
                                class="iconfont icon-gengduo"
                                style="color: #2370eb; font-weight: 600"
                            ></i>
                        </span>
                        <el-dropdown-menu
                            slot="dropdown"
                            style="margin-left: 10px; text-align: center"
                        >
                            <!-- <el-dropdown-item>
                                <el-button
                                    style="color: #2370eb"
                                    v-if="
                                        btnP.delay &&
                                        btnStatus('报备延期', scope.row)
                                    "
                                    type="text"
                                    @click="
                                        ($event) => {
                                            onBtnFun($event, scope.row);
                                        }
                                    "
                                    >报备延期</el-button
                                >
                            </el-dropdown-item> -->
                            <!-- <el-dropdown-item>
                                <el-button
                                    style="color: #2370eb"
                                    v-if="
                                        btnP.changeTrial &&
                                        btnStatus('变更试用', scope.row)
                                    "
                                    type="text"
                                    @click="
                                        ($event) => {
                                            onBtnFun($event, scope.row);
                                        }
                                    "
                                    >变更试用</el-button
                                >
                            </el-dropdown-item> -->
                            <el-dropdown-item>
                                <el-button
                                    style="color: #2370eb"
                                    v-if="
                                        btnP.giveup &&
                                        btnStatus('放弃报备', scope.row)
                                    "
                                    @click="
                                        ($event) => {
                                            onBtnFun($event, scope.row);
                                        }
                                    "
                                    type="text"
                                    >放弃报备</el-button
                                >
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
import { reportList } from '@/api/report/report.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            btnList: [],
            btnP: {},
            pageData: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(active, name, searchVal, pageNum, pageSize, btnP) {
            this.btnList = [];
            this.btnP = btnP;
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                    productType: 4,
                },
                pageSize: pageSize,
                pageNum: pageNum,
            };
            var startTime = '';
            var endTime = '';
            if (searchVal.followTime != '') {
                let date = new Date();
                searchVal.followDate = [];
                if (searchVal.followTime == '今日') {
                    endTime = this.$searchTime.getNextDate(date, 0);
                    startTime = this.$searchTime.getNextDate(date, 0);
                } else if (
                    searchVal.followTime == '近7天' &&
                    (searchVal.field == 1 || searchVal.field == 3)
                ) {
                    startTime = this.$searchTime.getNextDate(date, 0);
                    endTime = this.$searchTime.getNextDate(date, -6);
                } else if (
                    searchVal.followTime == '近7天' &&
                    (searchVal.field == 2 || searchVal.field == 4)
                ) {
                    startTime = this.$searchTime.getNextDate(date, 7);
                    endTime = this.$searchTime.getNextDate(date, 0);
                } else if (searchVal.followTime == '本月') {
                    startTime = this.$searchTime.DateUtil.getStartDayOfMonth();
                    endTime = this.$searchTime.DateUtil.getEndDayOfMonth();
                }
            }
            if (searchVal.followDate && searchVal.followDate.length > 0) {
                startTime = searchVal.followDate[0];
                endTime = searchVal.followDate[1];
            }

            if (searchVal.field == 1) {
                data.param.startTrialExpireDate = startTime;
                data.param.endTrialExpireDate = endTime;
            } else if (searchVal.field == 2) {
                data.param.startReportStartDate = startTime;
                data.param.endReportStartDate = endTime;
            } else if (searchVal.field == 3) {
                data.param.startReportExpireDate = startTime;
                data.param.endReportExpireDate = endTime;
            } else if (searchVal.field == 4) {
                data.param.startCreateDate = startTime;
                data.param.endCreateDate = endTime;
            }
            if (searchVal.libraryCategoryVal != '') {
                data.param.libraryCategory = searchVal.libraryCategoryVal;
            }
            if (name) {
                data.param.name = name;
            }
            if (searchVal.channelManageId) {
                data.param.channelManageId = searchVal.channelManageId;
            }
            switch (active) {
                case '已报备':
                    data.param.reportStatusList = [3, 11, 12, 13, 14];
                    break;
                case '申请中':
                    data.param.all = 1;
                    data.param.reportStatusList = [
                        8, 9, 11, 12, 13, 14, 15, 16,
                    ];
                    data.param.trialStatusList = [7, 8, 15, 16];
                    break;
                case '试用中':
                    data.param.trialStatusList = [3];
                    break;
                case '试用到期':
                    data.param.trialStatusList = [4];
                    break;
                case '草稿箱':
                    data.param.reportStatusList = [6];
                    break;
                case '已成交':
                    data.param.reportStatusList = [18];
                    break;
                case '已失效':
                    data.param.reportStatusList = [17, 10, 7, 19];
                    break;
            }
            // console.log(searchVal);
            // console.log(name);
            // console.log(active);

            this.pageData = {
                searchVal,
                name,
                active,
                pageNum,
                pageSize,
            };
            reportList(data).then((res) => {
                if (res.code == 200) {
                    this.$emit('totalNum', res.data.total);
                    this.tableData = res.data.list;
                }
            });
            if (btnP.add) {
                this.btnList.push({
                    name: '申请报备',
                });
            }
            if (btnP.update) {
                this.btnList.push({
                    name: '编辑',
                });
            }
            if (btnP.revoke) {
                this.btnList.push({
                    name: '撤销报备',
                });
            }
            if (btnP.addTrial) {
                this.btnList.push({
                    name: '试用申请',
                });
            }
            if (btnP.addDomain) {
                this.btnList.push({
                    name: '添加域名',
                });
            }
            if (btnP.delay) {
                this.btnList.push({
                    name: '报备延期',
                });
            }
            if (btnP.changeTrial) {
                this.btnList.push({
                    name: '变更试用',
                });
            }
            if (btnP.giveup) {
                this.btnList.push({
                    name: '放弃报备',
                });
            }
        },
        onBtnFun(e, row) {
            if (e.target.innerText == '申请报备') {
                this.$emit('onReport', row);
            }
            if (e.target.innerText == '编辑') {
                this.$emit('onEdit', row);
            }
            if (e.target.innerText == '撤销报备') {
                this.$emit('onRevoke', row);
            }
            if (e.target.innerText == '试用申请') {
                this.$emit('onTrial', row);
            }
            if (e.target.innerText == '添加域名') {
                this.$emit('onDomain', row);
            }
            if (e.target.innerText == '报备延期') {
                this.$emit('onDelay', row);
            }
            if (e.target.innerText == '试用延期') {
                this.$emit('onTrialDelay', row);
            }
            if (e.target.innerText == '变更试用') {
                this.$emit('onChangeTrial', row);
            }

            if (e.target.innerText == '放弃报备') {
                this.$emit('onGiveUp', row);
            }
            if (e.target.innerText == '重新报备') {
                this.$emit('onAgainReport', row);
            }
        },
        handleCommand(val) {
            console.log(val);
            if (val.name == '申请报备') {
                this.$emit('onReport', val.row);
            }
            if (val.name == '编辑') {
                this.$emit('onEdit', val.row);
            }
            if (val.name == '撤销报备') {
                this.$emit('onRevoke', val.row);
            }
            if (val.name == '试用申请') {
                this.$emit('onTrial', val.row);
            }
            if (val.name == '添加域名') {
                this.$emit('onDomain', val.row);
            }
            if (val.name == '报备延期') {
                this.$emit('onDelay', val.row);
            }
            if (val.name == '试用延期') {
                this.$emit('onTrialDelay', val.row);
            }
            if (val.name == '变更试用') {
                this.$emit('onChangeTrial', val.row);
            }
            if (val.name == '放弃报备') {
                this.$emit('onGiveUp', val.row);
            }
        },
        isShow(row) {
            if (
                row.reportStatus == 9 ||
                row.reportStatus == 11 ||
                row.reportStatus == 12 ||
                row.reportStatus == 13 ||
                row.reportStatus == 14 ||
                row.reportStatus == 15 ||
                row.reportStatus == 16 ||
                row.trialStatus == 7 ||
                row.trialStatus == 8 ||
                row.trialStatus == 10 ||
                row.trialStatus == 11 ||
                row.trialStatus == 13 ||
                row.trialStatus == 14 ||
                row.trialStatus == 15 ||
                row.trialStatus == 16 ||
                row.trialStatus == 17
            ) {
                return false;
            } else {
                return true;
            }
        },
        onReportDetail(row) {
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            this.$emit('onReportDetail', row);
        },
        onHistory(row) {
            this.$emit('onHistory', row);
        },
        onDetail(row) {
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            this.$emit('onDetail', row);
        },
        // 按钮根据状态显示
        btnStatus(name, row) {
            switch (name) {
                case '申请报备':
                    if (
                        row.reportStatus == 6 ||
                        row.reportStatus == 7 ||
                        row.reportStatus == 10
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                case '编辑':
                    if (
                        row.reportStatus == 6 ||
                        row.reportStatus == 7 ||
                        row.reportStatus == 10
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                case '撤销报备':
                    if (row.reportStatus == 8) {
                        return true;
                    } else {
                        return false;
                    }
                case '试用申请':
                    if (
                        row.reportStatus == 3 &&
                        (row.trialStatus == 1 ||
                            row.trialStatus == 9 ||
                            row.trialStatus == 4)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                case '添加域名':
                    if (row.reportStatus == 3) {
                        return true;
                    } else {
                        return false;
                    }
                case '试用延期':
                    if (row.reportStatus == 3 && row.trialStatus == 3) {
                        return true;
                    } else {
                        return false;
                    }
                case '报备延期':
                    if (row.reportStatus == 3) {
                        return true;
                    } else {
                        return false;
                    }
                case '变更试用':
                    if (row.reportStatus == 3 && row.trialStatus == 3) {
                        return true;
                    } else {
                        return false;
                    }
                case '放弃报备':
                    if (row.reportStatus == 3) {
                        return true;
                    } else {
                        return false;
                    }
                case '重新报备':
                    if (row.reportStatus == 17 || row.reportStatus == 19) {
                        return true;
                    } else {
                        return false;
                    }
            }
            // return true;
        },
        // 域名处理
        returnDomain(row) {
            if (row.trialDomain) {
                if (row.nicheDomainBOList && row.nicheDomainBOList.length > 0) {
                    let index = row.nicheDomainBOList.findIndex((item) => {
                        return (
                            item.auditStatus == 1 &&
                            row.trialDomain == item.domain
                        );
                    });

                    if (index != -1) {
                        return row.nicheDomainBOList[index];
                    } else {
                        return row.nicheDomainBOList[0];
                    }
                } else {
                    return '- -';
                }
            }
            if (row.nicheDomainBOList && row.nicheDomainBOList.length > 0) {
                let index = row.nicheDomainBOList.findIndex((item) => {
                    return item.auditStatus == 1;
                });

                if (index != -1) {
                    return row.nicheDomainBOList[index];
                } else {
                    return row.nicheDomainBOList[0];
                }
            } else {
                return '- -';
            }
        },
        
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
    }
}
// /deep/ .el-table th:nth-child(7) .cell {
//     padding-left: 20px;
// }
// /deep/ td:nth-child(7) .cell {
//     padding-left: 0;
// }
/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.el-button + .el-button {
    margin-left: 0;
}
.item {
    p + p {
        margin-top: 10px;
    }
    span {
        display: inline-block;
        vertical-align: middle;
        width: 70px;
        font-size: 12px;
        text-align: center;
        height: 25px;
        line-height: 25px;
        border-radius: 3px;
        margin-left: 10px;
    }
    .success {
        border: 1px solid #2370eb;
        i {
            color: #2370eb;
        }
    }
    .warning {
        border: 1px solid #d0021b;
        i {
            color: #d0021b;
        }
    }
    .wait {
        border: 1px solid #f5a623;
        i {
            color: #f5a623;
        }
    }
    .faid {
        border: 1px solid #d0021b;
        i {
            color: #d0021b;
        }
    }
}
.el-button {
    margin-right: 10px;
}
</style>
